.BcProcessor__Container {
  width: 100%;
  height: 100%;
  padding-bottom: 15px;
  h6 { padding-left: 15px; }
  p.empty-list { padding-left: 15px; }
  button {
    margin-left: 15px; padding: 0; background: transparent; color: #5f5fff;
    &:hover { background: transparent; color: #5f5fff;}
  }
}

.Tx__List {
  width: 100%;
  height: auto;
  text-align: left;
  &--nothing { padding-left: 15px; }
}

.Tx {
  border-top: 1px solid #eeeeee;
  width: calc(100% - 30px);
  display: flex;
  flex-direction: row;
  padding: 10px 15px;
  margin: 10px auto;
  &:last-of-type {
    border-bottom: 1px solid #eeeeee;
  }
  .Tx__Main {
    flex-basis: 75%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    .Tx__Status {
      font-size: 1em;
    }
    .Tx__Description {
      margin: 10px 0px;
      font-size: 1.35em;
    }
    .Tx__Hash {
      font-size: .75em;
    }
  }
  .Tx__Secondary {
    flex-basis: 25%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
