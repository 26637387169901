.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
}

.inner {
  background: white;
  border-radius: 40px;
  position: relative;
  width: 50%;
  z-index: 1;
}

.inner:before {
  background-color: #ffffff;
  background-color: rgba(0, 0, 0, .1);
  border-radius: 40px;
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.image {
  display: block;
  margin: auto;
  /* csslint ignore:start */
  padding: 10px 0 10px 0;
  /* csslint ignore:end */
  width: 35%;
}

.loader {
  height: 80px;
  width: 80px;
}

.hidden {
  opacity: 0;
}

.fadeIn {
  opacity: 1;
  transition: opacity 1s;
}

.text {
  padding: 0 40px 20px 40px;
  text-align: center;
}

@media (min-width: 1280px) {
  .inner {
    width: 35%;
  }
}

@media (orientation: portrait) {
  .inner {
    width: 75%;
  }

  .image {
    width: 50%;
  }
}

@media (max-width: 960px) and (orientation: landscape) {
  .inner {
    width: 75%;
  }

  .image {
    width: 15%
  }
}
