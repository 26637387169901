@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,500,700');
@import '_variables.scss';

/* Base Styles */
body {
  margin: 0;
  padding: 0;
  font-family: 'Rubik', sans-serif;
  background-color: white;
  color: #333;
}

h1, h2, h3, h4 {
  font-weight: 700;
  color: rgba(38,28,70,1);
}

h5, h6 {
  font-weight: 700;
}

a {
  text-decoration: none;
  color: #5F5FFF;
}

input {
  padding: 10px 5px;
  outline: none;
  border: none;
  border-bottom: 1px solid rgba(95,95,255,0.5);
  transition: all .15s linear;
  margin-bottom: 1px;
  width: 100%;
  font-size: 1em;
  background-color: transparent;
}

input:focus {
  border-bottom: 2px solid rgba(95,95,255,1);
  margin-bottom: 0px;
}
