@import url(https://fonts.googleapis.com/css?family=Rubik:300,400,500,700);
@import url(https://fonts.googleapis.com/css?family=Rubik:300,400,500,700);
// Color Palette

$base-color: #5f5fff;
$cream: rgba(249,248,244,1);
$sky: rgba(132,206,200,1);
$success: rgba(166,191,76,1);
$danger: rgba(248, 128, 115,1);

// Typography
$base-font: 'Rubik', sans-serif;

// Padding
$base-padding: 25px;

$tablet: 768px;
$phone: 480px;

/* Base Styles */
body {
  margin: 0;
  padding: 0;
  font-family: 'Rubik', sans-serif;
  background-color: white;
  color: #333;
}

h1, h2, h3, h4 {
  font-weight: 700;
  color: rgba(38,28,70,1);
}

h5, h6 {
  font-weight: 700;
}

a {
  text-decoration: none;
  color: #5F5FFF;
}

input {
  padding: 10px 5px;
  outline: none;
  border: none;
  border-bottom: 1px solid rgba(95,95,255,0.5);
  transition: all .15s linear;
  margin-bottom: 1px;
  width: 100%;
  font-size: 1em;
  background-color: transparent;
}

input:focus {
  border-bottom: 2px solid rgba(95,95,255,1);
  margin-bottom: 0px;
}

.Distance__notice {
  color: #5f5fff; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  z-index: 99999;
  transition: all .15s linear; }

.modal-main {
  position: fixed;
  background: white;
  max-width: 800px;
  width: 80%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
  z-index: 2; }
  .modal-main .header button {
    position: absolute;
    top: 25px;
    right: 25px;
    z-index: 1; }
  .modal-main .contents {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 50px;
    min-height: 420px;
    height: auto;
    position: relative; }
  .modal-main .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
    .modal-main .footer button {
      position: absolute;
      bottom: 25px; }
  .modal-main .result {
    text-align: center; }

.display-block {
  opacity: 1; }

.display-none {
  opacity: 0;
  pointer-events: none; }

.Modal__backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1; }

.Wandering {
  width: 100%; }

.Wandering__bar {
  background-color: #5f5fff; }
  .Wandering__bar .contents {
    max-width: 1400px;
    margin: 0 auto;
    height: 80px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center; }
    .Wandering__bar .contents p:nth-of-type(1) {
      color: #261c46;
      font-weight: 700; }
    .Wandering__bar .contents p:nth-of-type(2) {
      flex-basis: 50%;
      margin: 0px 15px;
      font-weight: 300; }

.Wandering__container {
  display: flex;
  min-height: calc(100vh - 80px);
  flex-wrap: wrap; }
  .Wandering__container .sidenav {
    width: 500px; }
    @media (max-width: 768px) {
      .Wandering__container .sidenav {
        flex-basis: 100%; } }
  .Wandering__container .Wandering__info {
    position: relative;
    padding: 15px 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    border-bottom: 2px solid #eee; }
    .Wandering__container .Wandering__info h2 {
      flex-basis: 100%;
      margin: 0; }
    .Wandering__container .Wandering__info p {
      flex-basis: 100%; }
    .Wandering__container .Wandering__info svg {
      width: 60px;
      height: 60px;
      vertical-align: middle;
      margin-right: 10px; }
    .Wandering__container .Wandering__info--bar {
      position: absolute;
      left: 0;
      width: 100%;
      height: 4px;
      top: 0; }
  .Wandering__container .Wandering__data {
    border-bottom: 1px solid #efefef;
    padding-top: 30px;
    padding-bottom: 25px; }
    .Wandering__container .Wandering__data .Columns__Column--33 {
      text-align: center;
      flex-basis: 33% !important; }
      .Wandering__container .Wandering__data .Columns__Column--33 p {
        margin: 0;
        color: #aaa; }
      .Wandering__container .Wandering__data .Columns__Column--33 h2 {
        margin: 0; }
  .Wandering__container .Wandering__map {
    flex-basis: calc(100% - 500px); }
    @media (max-width: 768px) {
      .Wandering__container .Wandering__map {
        height: 100vh;
        flex-basis: 100%; } }

.Wandering__transfer {
  padding: 25px; }

.Wandering__gas {
  border-top: 1px solid #efefef;
  padding: 25px; }
  .Wandering__gas .button {
    margin-top: 10px; }

/* Old Styles. Replacing */
.Wandering__token-id {
  font-size: 3em; }

.Wandering__suggestion-icon {
  margin-right: 8px; }

.Wandering__search-input-container {
  position: relative; }

.Wandering__search-input,
.Wandering__search-input:focus,
.Wandering__search-input:active {
  width: 100%; }

.Wandering__clear-button,
.Wandering__clear-button:active,
.Wandering__clear-button:focus {
  position: absolute;
  right: 16px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background: transparent;
  border: none;
  outline: none;
  font-weight: 600;
  color: #999; }

.Wandering__autocomplete-container {
  border-bottom: honeydew;
  border-left: honeydew;
  border-right: honeydew;
  border-top: 1px solid #e6e6e6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 2px 2px; }

.Wandering__suggestion-item {
  padding: 8px;
  text-align: left;
  background-color: #fff;
  cursor: pointer; }

.Wandering__suggestion-item--active {
  background-color: #fafafa; }

.Wandering__dropdown-footer {
  display: flex;
  justify-content: flex-end;
  padding: 4px; }

.Wandering__dropdown-footer-image {
  display: inline-block;
  width: 150px; }

.Wandering__spinner {
  color: #18bc9c;
  font-size: 30px; }

.Wandering__error-message {
  color: red; }

.Wandering__geocode-result-header {
  font-size: 20px;
  color: #222222; }

.step--1 {
  padding-top: 25px;
  padding-bottom: 25px; }

.step--2 {
  padding-bottom: 25px; }

.step--3 {
  padding-bottom: 25px; }

.Wandering__wallet-address {
  position: relative; }

.button--qr {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  background: transparent;
  cursor: pointer;
  transition: all .15s linear;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0px;
  outline: none; }
  .button--qr svg {
    width: 28px;
    fill: #5f5fff;
    transition: all .15s linear; }
  .button--qr:hover {
    background: transparent; }
    .button--qr:hover svg {
      fill: #000; }

.ConfirmAutolocated {
  margin-top: -20px; }
  .ConfirmAutolocated p {
    color: red; }
  .ConfirmAutolocated button {
    margin-left: 10px; }


.Tokens__Container {
  max-width: 1200px;
  margin: 50px auto;
  display: flex;
  flex-wrap: wrap;
  padding: 0px 25px; }
  .Tokens__Container .Tokens__Header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap; }
    .Tokens__Container .Tokens__Header button {
      margin-left: 30px; }
      @media (max-width: 768px) {
        .Tokens__Container .Tokens__Header button {
          margin-left: 0px;
          flex-basis: 100%; } }
  .Tokens__Container .Tokens__Explore {
    flex-basis: 100%;
    margin-top: 25px; }

.divTable {
  display: flex;
  flex-direction: column;
  width: 100%; }

.divTableRow {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #eeeeee;
  padding: 15px 0px;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap; }
  .divTableRow .divTableCell:nth-of-type(1) {
    width: 5%; }
    @media (max-width: 768px) {
      .divTableRow .divTableCell:nth-of-type(1) {
        flex-basis: 10%; } }
  .divTableRow .divTableCell:nth-of-type(2) {
    width: 20%; }
    .divTableRow .divTableCell:nth-of-type(2) svg {
      width: 28px;
      height: 28px;
      vertical-align: middle;
      margin-right: 5px; }
    @media (max-width: 768px) {
      .divTableRow .divTableCell:nth-of-type(2) {
        flex-basis: 90%; } }
  .divTableRow .divTableCell:nth-of-type(3) {
    width: 50%; }
    @media (max-width: 768px) {
      .divTableRow .divTableCell:nth-of-type(3) {
        margin-top: 15px;
        flex-basis: 100%;
        font-size: 1em; } }
  .divTableRow .divTableCell:nth-of-type(4) {
    width: 10%; }
    .divTableRow .divTableCell:nth-of-type(4) span {
      display: none;
      font-size: .75em;
      color: #aaa; }
    @media (max-width: 768px) {
      .divTableRow .divTableCell:nth-of-type(4) {
        margin-top: 15px;
        flex-basis: 33%;
        text-align: center; }
        .divTableRow .divTableCell:nth-of-type(4) span {
          display: block; } }
  .divTableRow .divTableCell:nth-of-type(5) {
    width: 10%; }
    .divTableRow .divTableCell:nth-of-type(5) span {
      display: none;
      font-size: .75em;
      color: #aaa; }
    @media (max-width: 768px) {
      .divTableRow .divTableCell:nth-of-type(5) {
        margin-top: 15px;
        flex-basis: 33%;
        text-align: center; }
        .divTableRow .divTableCell:nth-of-type(5) span {
          display: block; } }
  .divTableRow .divTableCell:nth-of-type(6) {
    width: 5%; }
    .divTableRow .divTableCell:nth-of-type(6) span {
      display: none;
      font-size: .75em;
      color: #aaa; }
    @media (max-width: 768px) {
      .divTableRow .divTableCell:nth-of-type(6) {
        margin-top: 15px;
        text-align: center;
        flex-basis: 33%; }
        .divTableRow .divTableCell:nth-of-type(6) span {
          display: block; } }
  .divTableRow .divTableCell.Column--Heading {
    color: #999999; }
    @media (max-width: 768px) {
      .divTableRow .divTableCell.Column--Heading {
        display: none; } }

@media (max-width: 768px) {
  .divTableRow:first-of-type {
    border-bottom: 1px solid #efefef; }
    .divTableRow:first-of-type .divTableCell:nth-of-type(4),
    .divTableRow:first-of-type .divTableCell:nth-of-type(5),
    .divTableRow:first-of-type .divTableCell:nth-of-type(6) {
      display: none; } }

.Paginator {
  display: flex;
  align-items: center;
  padding: 15px 0px; }
  .Paginator button:nth-of-type(1), .Paginator span {
    margin-right: 15px; }

.BcProcessor__Container {
  width: 100%;
  height: 100%;
  padding-bottom: 15px; }
  .BcProcessor__Container h6 {
    padding-left: 15px; }
  .BcProcessor__Container p.empty-list {
    padding-left: 15px; }
  .BcProcessor__Container button {
    margin-left: 15px;
    padding: 0;
    background: transparent;
    color: #5f5fff; }
    .BcProcessor__Container button:hover {
      background: transparent;
      color: #5f5fff; }

.Tx__List {
  width: 100%;
  height: auto;
  text-align: left; }
  .Tx__List--nothing {
    padding-left: 15px; }

.Tx {
  border-top: 1px solid #eeeeee;
  width: calc(100% - 30px);
  display: flex;
  flex-direction: row;
  padding: 10px 15px;
  margin: 10px auto; }
  .Tx:last-of-type {
    border-bottom: 1px solid #eeeeee; }
  .Tx .Tx__Main {
    flex-basis: 75%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center; }
    .Tx .Tx__Main .Tx__Status {
      font-size: 1em; }
    .Tx .Tx__Main .Tx__Description {
      margin: 10px 0px;
      font-size: 1.35em; }
    .Tx .Tx__Main .Tx__Hash {
      font-size: .75em; }
  .Tx .Tx__Secondary {
    flex-basis: 25%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end; }


.GasTank {
  width: 100%; }
  .GasTank .GasTank__gas {
    position: relative;
    width: 120px;
    float: left;
    text-align: center; }
    .GasTank .GasTank__gas .label--full {
      position: absolute;
      top: 70px;
      right: 15px;
      color: #A6BF4C; }
    .GasTank .GasTank__gas .label--empty {
      position: absolute;
      top: 70px;
      left: 25px;
      color: red; }
  .GasTank .GasTank__info {
    float: right;
    width: calc(100% - 170px);
    padding-right: 25px;
    padding-left: 25px; }

.Header {
  height: auto;
  background-color: white;
  height: 80px;
  display: flex;
  align-items: center;
  padding-left: 25px;
  padding-right: 25px;
  width: calc(100% - 50px);
  border-bottom: 2px solid #eee; }

.Header__title {
  color: #5f5fff;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 2em;
  font-weight: 300; }
  @media (max-width: 889px) {
    .Header__title {
      font-size: 1.5em; } }

.Logo {
  flex-basis: 33%;
  margin-right: auto; }
  @media (max-width: 889px) {
    .Logo {
      flex-basis: 75%; } }

.Navigation--Desktop {
  margin-left: 0;
  display: flex;
  justify-content: center;
  align-items: center; }
  .Navigation--Desktop > *:not(first-child) {
    margin-left: 50px; }

.button--BcProcessor svg {
  fill: white; }

.dropdown {
  z-index: 99;
  position: absolute;
  top: 0;
  right: 0; }
  .dropdown .dropdown--processor {
    position: absolute;
    top: 80px;
    right: 0;
    width: 420px;
    height: auto;
    background: white;
    z-index: 1;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); }
  .dropdown .dropdown--backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1; }

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 0px 0px;
  cursor: pointer;
  transition-property: opacity, -webkit-filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  outline: none;
  overflow: visible;
  transition: all .15s linear; }
  @media (min-width: 890px) {
    .hamburger {
      display: none; } }
  @media (max-width: 889px) {
    .hamburger {
      display: inline-block; } }
  .hamburger:hover {
    opacity: 1; }
    .hamburger:hover .hamburger-inner, .hamburger:hover .hamburger-inner::before, .hamburger:hover .hamburger-inner::after {
      transition: all .15s linear;
      background-color: #261c46; }

.hamburger-box {
  width: 28px;
  height: 21px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1.5px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 28px;
    height: 3px;
    background-color: #5f5fff;
    border-radius: 2px;
    position: absolute;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -9px; }
  .hamburger-inner::after {
    bottom: -9px; }

.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spin .hamburger-inner::before {
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }
  .hamburger--spin .hamburger-inner::after {
    transition: bottom 0.1s 0.25s ease-in, -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin.is-active .hamburger-inner {
  -webkit-transform: rotate(225deg);
          transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  background-color: #261c46; }
  .hamburger--spin.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    background-color: #261c46;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }
  .hamburger--spin.is-active .hamburger-inner::after {
    bottom: 0;
    background-color: #261c46;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    transition: bottom 0.1s ease-out, -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

.Navbar__Mobile {
  position: fixed;
  padding: 75px 25px 25px 25px;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100vh;
  background: #5f5fff;
  opacity: 0;
  z-index: -1;
  transition: all .1s linear; }
  .Navbar__Mobile--Contents {
    display: flex;
    flex-direction: column;
    align-items: flex-end; }
    .Navbar__Mobile--Contents--Item {
      font-size: 1.25em;
      margin-top: 25px;
      color: white; }
  .Navbar__Mobile--Open {
    opacity: 1;
    z-index: 1;
    pointer-events: all; }

.navbar__hamburger {
  position: absolute;
  top: 29px;
  right: 25px;
  z-index: 2;
  cursor: pointer; }
  .navbar__hamburger:hover {
    background-color: transparent !important; }

.navbar__hamburger,
.Navbar__Mobile {
  display: block; }
  @media (min-width: 890px) {
    .navbar__hamburger,
    .Navbar__Mobile {
      display: none; } }

.Navigation--Desktop {
  margin-left: auto;
  display: none; }
  @media (min-width: 890px) {
    .Navigation--Desktop {
      display: flex;
      align-items: center;
      justify-content: center; } }

body {
  margin: 0;
  padding: 0;
  font-family: 'Rubik', sans-serif;
  background-color: white;
  color: #333;
  font-weight: 400; }

h1, h2, h3 {
  font-weight: 100; }

p.tiny {
  font-size: .75em; }

p.large {
  font-size: 1.15em;
  color: #999;
  font-weight: 100; }

.color--success {
  color: #A6BF4C; }

.color--danger {
  color: #F88073; }

button, .button {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  outline: none;
  background-color: #5f5fff;
  color: white;
  padding: 10px 15px;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  cursor: pointer;
  border: none;
  letter-spacing: .05em;
  font-weight: 500;
  transition: all .15s linear; }
  button:hover, .button:hover {
    background-color: #261c46;
    color: white; }
  button:disabled, .button:disabled {
    background-color: #595959;
    color: #a6a6a6; }
  button svg, .button svg {
    fill: white; }
  button.invert, .button.invert {
    background-color: white;
    color: #5f5fff; }
  button.od-primary, .button.od-primary {
    background-color: transparent;
    color: #261c46;
    border: 2px solid #261c46; }
  button .transparent, .button .transparent {
    background-color: transparent;
    color: #5f5fff; }

.Columns {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start; }
  .Columns__Column {
    flex-direction: column; }
    .Columns__Column--25 {
      flex-basis: 25%;
      flex-direction: column; }
      @media (max-width: 768px) {
        .Columns__Column--25 {
          flex-basis: 100%; } }
    .Columns__Column--33 {
      flex-basis: 33%;
      flex-direction: column; }
      @media (max-width: 768px) {
        .Columns__Column--33 {
          flex-basis: 100%; } }
    .Columns__Column--50 {
      flex-basis: 50%;
      flex-direction: column; }
      @media (max-width: 768px) {
        .Columns__Column--50 {
          flex-basis: 100%; } }
    .Columns__Column--100 {
      flex-basis: 100%;
      flex-direction: column; }

.gm-style .gm-style-iw {
  font-weight: 400;
  font-size: 16px;
  max-width: 320px; }

.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
}

.inner {
  background: white;
  border-radius: 40px;
  position: relative;
  width: 50%;
  z-index: 1;
}

.inner:before {
  background-color: #ffffff;
  background-color: rgba(0, 0, 0, .1);
  border-radius: 40px;
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.image {
  display: block;
  margin: auto;
  /* csslint ignore:start */
  padding: 10px 0 10px 0;
  /* csslint ignore:end */
  width: 35%;
}

.loader {
  height: 80px;
  width: 80px;
}

.hidden {
  opacity: 0;
}

.fadeIn {
  opacity: 1;
  transition: opacity 1s;
}

.text {
  padding: 0 40px 20px 40px;
  text-align: center;
}

@media (min-width: 1280px) {
  .inner {
    width: 35%;
  }
}

@media (orientation: portrait) {
  .inner {
    width: 75%;
  }

  .image {
    width: 50%;
  }
}

@media (max-width: 960px) and (orientation: landscape) {
  .inner {
    width: 75%;
  }

  .image {
    width: 15%
  }
}

