.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.85);
    z-index: 99999;
    transition: all .15s linear;
  }

.modal-main {
  position:fixed;
  background: white;
  max-width: 800px;
  width: 80%;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  text-align: center;
  z-index: 2;
  .header {
    button {
      position: absolute;
      top: 25px;
      right: 25px;
      z-index: 1;
    }
  }
  .contents {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 50px;
    min-height: 420px;
    height: auto;
    position: relative;
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    button {
      position: absolute;
      bottom: 25px;
    }
  }
  .result {
    text-align: center;
  }
}

.display-block {
  opacity: 1;
}

.display-none {
  opacity: 0;
  pointer-events: none;
}

.Modal__backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
