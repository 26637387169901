@import '../../_variables.scss';

.Wandering {
  width: 100%;
}

.Wandering__bar {
  background-color: $base-color;
  .contents {
    max-width: 1400px;
    margin: 0 auto;
    height: 80px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    p:nth-of-type(1) {
      color: rgba(38,28,70,1);
      font-weight: 700;
    }
    p:nth-of-type(2) {
      flex-basis: 50%;
      margin: 0px 15px;
      font-weight: 300;
    }
  }
}

.Wandering__container {
  display: flex;
  min-height: calc(100vh - 80px);
  flex-wrap: wrap;
  .sidenav {
    width: 500px;
    @media (max-width: $tablet) {
      flex-basis: 100%;
    }
  }
  .Wandering__info {
    position: relative;
    padding: 15px 25px;
    h2 { flex-basis: 100%; margin: 0; }
    p { flex-basis: 100%; }
    svg { width: 60px; height: 60px; vertical-align: middle; margin-right: 10px;}
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    border-bottom: 2px solid #eee;
    &--bar {
      position: absolute;
      left: 0;
      width: 100%;
      height: 4px;
      top: 0;
    }
  }
  .Wandering__data {
    border-bottom: 1px solid #efefef;
    padding-top: 30px;
    padding-bottom: 25px;
    .Columns__Column--33 {
      text-align: center;
      flex-basis: 33% !important;
      p { margin: 0; color: #aaa; }
      h2 { margin: 0;}
    }
  }
  .Wandering__map {
    flex-basis: calc(100% - 500px);
    @media (max-width: $tablet) {
      height: 100vh;
      flex-basis: 100%;
    }
  }
}

.Wandering__transfer {
  padding: $base-padding;
}

// Gas Tank
.Wandering__gas {
  border-top: 1px solid #efefef;
  padding: $base-padding;
  .button {
    margin-top: 10px;
  }
}

/* Old Styles. Replacing */

.Wandering__info {
}

.Wandering__token-id {
  font-size: 3em;
}
.Wandering__contract {
}

.Wandering__form {
}


.Wandering__suggestion-icon {
  margin-right: 8px;
}

.Wandering__search-bar-container {
}

.Wandering__search-input-container {
  position: relative;
}

.Wandering__search-input,
.Wandering__search-input:focus,
.Wandering__search-input:active {
  width: 100%;
}

.Wandering__clear-button,
.Wandering__clear-button:active,
.Wandering__clear-button:focus {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  outline: none;
  font-weight: 600;
  color: #999;
}

.Wandering__autocomplete-container {
  border-bottom: honeydew;
  border-left: honeydew;
  border-right: honeydew;
  border-top: 1px solid #e6e6e6;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  border-radius: 0 0 2px 2px;
}

.Wandering__suggestion-item {
  padding: 8px;
  text-align: left;
  background-color: #fff;
  cursor: pointer;
}

.Wandering__suggestion-item--active {
  background-color: #fafafa;
}

.Wandering__dropdown-footer {
  display: flex;
  justify-content: flex-end;
  padding: 4px;
}

.Wandering__dropdown-footer-image {
  display: inline-block;
  width: 150px;
}

.Wandering__spinner {
  color: #18bc9c;
  font-size: 30px;
}

.Wandering__error-message {
  color: red;
}

.Wandering__geocode-result-header {
  font-size: 20px;
  color: #222222;
}

.step--1 {
  padding-top: 25px;
  padding-bottom: 25px;
}

.step--2 {
  padding-bottom: 25px;
}

.step--3 {
  padding-bottom: 25px;
}

.Wandering__wallet-address {
  position: relative;
}

.button--qr {
  appearance: none;
  border: none;
  background: transparent;
  cursor: pointer;
  transition: all .15s linear;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0px;
  outline: none;
  svg {
    width: 28px;
    fill: #5f5fff;
    transition: all .15s linear;
  }
  &:hover {
    background: transparent;
    svg {
      fill: #000;
    }
  }
}

.ConfirmAutolocated {
  margin-top: -20px;
  p { color: red;}
  button {
    margin-left: 10px;
  }
}

.Wandering__map img {
}
