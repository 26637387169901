// Color Palette

$base-color: #5f5fff;
$cream: rgba(249,248,244,1);
$sky: rgba(132,206,200,1);
$success: rgba(166,191,76,1);
$danger: rgba(248, 128, 115,1);

// Typography
$base-font: 'Rubik', sans-serif;

// Padding
$base-padding: 25px;

$tablet: 768px;
$phone: 480px;
