$primary-color: rgba(38,28,70,1);

.Header {
  height: auto;
  background-color: white;
  height: 80px;
  display: flex;
  align-items: center;
  padding-left: 25px;
  padding-right: 25px;
  width: calc(100% - 50px);
  border-bottom: 2px solid #eee;
}

.Header__title {
  color: #5f5fff;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 2em;
  font-weight: 300;
  @media (max-width: 889px) {
    font-size: 1.5em;
  }
}

.Logo {
  flex-basis: 33%;
  margin-right: auto;
  @media (max-width: 889px) {
    flex-basis: 75%;
  }
}

.Navigation--Desktop {
  margin-left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  > *:not(first-child) {
    margin-left: 50px;
  }
}

.button--BcProcessor {
  svg {
    fill: white;
  }
}
.dropdown {
  z-index: 99;
  position: absolute;
  top: 0;
  right: 0;
  .dropdown--processor {
    position: absolute;
    top: 80px;
    right: 0;
    width: 420px;
    height: auto;
    background: white;
    z-index: 1;
    box-shadow: 0px 4px 4px rgba(0,0,0,.25);
  }
  .dropdown--backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
  }
}
